<template>
  <div class="text-center">

    <div class="my-3">Test Page</div>

    <PrimePreloader/>

    <button class="btn btn-success" @click="$message({title: 'Успешно', text: 'Success'})">
      Success
    </button>
    <button class="btn btn-danger" @click="$error({title: 'Произошла ошибка', text: 'Error'})">
      Error
    </button>

  </div>
</template>

<script>


/*

this.$message({title: 'Успешно', text: 'successText'})
this.$error({title: 'Произошла ошибка', text: 'errorText'})


this.$message({text: 'successText'})
this.$error({text: 'errorText'})


const errorText = res.errors[0].map(err => err.message).join('\n')
this.$error({title: 'Добавление', text: errorText})

*/

// import httpClient from "@/services/http.service"


export default {
  name: "Test",
  data() {
    return {
      loading: true,
    }
  },
  methods: {},
  async mounted() {
  }
}
</script>
